import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgMeow/cover.png';
import background1 from './imgMeow/background1.png';
import background2 from './imgMeow/background2.png';
import interview1 from './imgMeow/interview1.png';
import interview2 from './imgMeow/interview2.png';
import designSolution1 from './imgMeow/designSolution1.png';
import designSolution3 from './imgMeow/designSolution3.png';
import techPip from './imgMeow/techPip.png';
import eye from './imgMeow/eye.gif';
import drag from './imgMeow/drag.gif';
import stella from './imgMeow/stella.gif';
import goodGirl from './imgMeow/goodGirl.gif';
import missU from './imgMeow/missU.gif';
import bye from './imgMeow/bye.gif';
import OD_DP from './imgMeow/OD_DP.png';
import modeling from './imgMeow/modeling.png';
import UT1 from './imgMeow/UT1.png';
import UT2 from './imgMeow/UT2.png';


const Meow = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="Meow">
            <div className="singleProject">
                <img className="cover" src={cover} alt="Meow"/>
                <h2 className="section-margin">
                    Meow : AI-Powered Cat Dairy
                </h2>
                <p className="leftAlign font-grey">
                    Meow is an <u>AI-driven</u> application that revolutionizes how cat owners connect with their pets.
                    At its heart is a <u>3D game environment</u> where a digital twin of the cat mimics its <u>real-world
                    activities</u>. 
                    <br/><br/>The app combines several key features:
                    <li>a dynamic 3D representation of the cat that updates in real time, </li>
                    <li>a conversational AI chatbot,</li>
                    <li>a comprehensive cat diary that logs activities automatically,</li>
                    <li>an augmented reality (AR) function for interactive engagement.</li>
                    <br/>This interactive platform enables owners to <u>stay connected</u> with their feline friends,
                    offering
                    insights into their behavior and enriching the human-cat relationship, even <u>when physical
                    presence</u>
                    is impossible. The app aims to close the communication gap between cats and their owners, fostering
                    a stronger bond.
                </p>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Product design, AR </p>
                        <p className="rightAlign"><b>Role :</b> Technique workflow, prototyping, YOLO, AR</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2023</p>
                        <p className="centerAlign"><b>Duration :</b> 4 Weeks</p>
                        <p className="rightAlign"><b>Team :</b> Sijia, Yiqi</p>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 Problems and Opportunities</h3>
                <p className="font-grey">Comparison with existing products</p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        • Existing cat monitors are not user-friendly, just serves as a camera.
                        We bridge the gap between the cat monitoring cameras and AI companion application with emotional
                        connection.
                    </p>
                    <img className="function-gif" src={background1} alt="background1"/>
                    <p className="leftAlign font-grey">
                        • For the AI and AR companion function, we craft the digital cat diary and itinerary notebook
                        with traceable records.
                    </p>
                    <img className="function-gif" src={background2} alt="background2"/>
                </div>
                <p className="font-grey">We Interviewed 10+ cat owners to scope down the problem spaces.</p>
                <img src={interview1} alt="interview"/>
                <p className="font-grey">Insights From User Interview </p>
                <div className="gif-container">
                    <p className="leftAlign font-grey">
                        6/10 cat owners we interviewed are interested in preserving their cat’s memory and know how
                        their cat are behaving when they are not at home.
                        The key insights we discovered during our interview process is that it is sometimes hard to
                        establish
                        direct feedback and interactions with your cat as they are unpredictable, but cat owners want to
                        preserve
                        those moments they share with their cat and wish for a way to interact with them as if they
                        understand them.
                    </p>
                    <img className="function-gif" src={interview2} alt="interview2"/>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">02 Design Solutions</h3>
                <p className="font-grey">We addressed the current cat owner’s need to interact and engage with their cat
                    as tangible memories from three aspects. </p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        • Translating cat movement and scene into interactive animated interface.
                    </p>
                    <img className="function-gif" src={designSolution1} alt="Design Solution"/>
                    <p className="leftAlign font-grey">
                        • AR interaction with your animate Cat with real sound feedback.
                    </p>
                    <img className="function-gif" src={eye} alt="Design Solution"/>
                    <p className="leftAlign font-grey">
                        • Dairy and AI-Chat bot with your cat to preserve unique memories.
                    </p>
                    <img className="function-gif" src={designSolution3} alt="Design Solution"/>
                </div>
                <p className="font-grey">Technical Pipeline</p>
                <img src={techPip} alt="Technical Pipeline"/>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">03 Technique Feature Breakdown</h3>

                <p className="font-grey">i Object Detection and Data Processing </p>
                <img src={OD_DP} alt="Object Detection and Data Processing"/>

                <p className="font-grey">ii 3d Modeling and Animation </p>
                <img src={modeling} alt="3d Modeling and Animation"/>

                <p className="font-grey">iii AR - Hololens </p>
                <div className="gif-container">
                    <div>
                        <p>Eye Tracking</p>
                        <img className="function-gif" src={eye} alt="Function GIF 1"/>
                    </div>
                    <div>
                        <p>Hand Drag</p>
                        <img className="function-gif" src={drag} alt="Function GIF 3"/>
                    </div>
                    <div>
                        <p>Voice Command -- “Stella”</p>
                        <img className="function-gif" src={stella} alt="Function GIF 5"/>
                    </div>
                    <div>
                        <p>Voice Command -- “Good girl”</p>
                        <img className="function-gif" src={goodGirl} alt="Function GIF 7"/>
                    </div>
                    <div>
                        <p>Voice Command -- “I miss you”</p>
                        <img className="function-gif" src={missU} alt="Function GIF 9"/>
                    </div>
                    <div>
                        <p>Voice Command -- “Bye Stella”</p>
                        <img className="function-gif" src={bye} alt="Function GIF 2"/>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">04 User testing</h3>
                <p className="font-grey">i On-site camera testing</p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        • We conducted on-site camera testing and image detection with our first user Stella using YOLO object detection.
                    </p>
                    <img className="function-gif" src={UT1} alt="On-site camera testing"/>
                </div>
                <p className="font-grey">ii System Usability Scale (SUS) Assessment Results </p>
                <img src={UT2} alt="System Usability Scale"/>


                <h3 className="section-title section-margin">05 Project Demo</h3>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/qS0QUii960g?si=Wjmbmr11h9Petd3U"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </article>
    );
};

export default Meow;
