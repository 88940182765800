import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import profileImage from './img/profile.jpg';
import logo1 from './img/softwareLogo/logo1.png';
import email from './img/email.png';
import cv from './img/cv.png';
import github from './img/github.png';
import linkedin from './img/linkedin.png';



const skillStack = [
    { img: logo1, name: 'Software 1' },
];

function About() {
    let navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const openCv = () => {
        window.open('/Jyuan_Liu_cv.pdf', '_blank');
    };

    const copyEmail = () => {
        navigator.clipboard.writeText('jyuanliudesign@gmail.com')
            .then(() => {
                alert('Email copied to clipboard!');
            })
            .catch(err => {
                console.error('Error copying email: ', err);
            });
    };

    const openLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="About">

            <section className="AboutIntro">
                <div className="AboutContent">
                    <p className="leftAlign">
                        Zhijuan is an accomplished Product Designer, renowned for her innovative approach in blending technology-driven design with human-centered research. Holding an MS in Digital and Material Technologies from the University of Michigan and a Bachelor's in Architecture from Tianjin University, she is currently enriching her expertise at Harvard University Graduate School of Design. Here, in her pursuit of an MS in Design Studies, Zhijuan focuses on the Technology domain, exploring the myriad ways in which AI and machine learning can revolutionize design.
                        <br/>
                        <br/>
                        Her professional ethos is anchored in the exploration of new frontiers in human-AI interactions. With a blend of academic excellence and practical experience, Zhijuan is adept at speculative design thinking and its application in unveiling new potentials within AI-enhanced design. This unique combination of skills and interests positions her as a transformative force in product design, particularly in areas intertwining technology with human usability and experience.
                    </p>
                    <br/>
                </div>
                <div className="AboutImage">
                    <img src={profileImage} alt="profileImage"/>
                    <div className="contact-info">
                        <img src={email} alt="email" onClick={copyEmail} style={{cursor: 'pointer'}}/>
                        <img src={cv} alt="cv" onClick={openCv} style={{cursor: 'pointer'}}/>
                        <img src={github} alt="github" onClick={() => openLink('https://github.com/quanyoya')}
                             style={{cursor: 'pointer'}}/>
                        <img src={linkedin} alt="linkedin"
                             onClick={() => openLink('https://www.linkedin.com/in/jyuan-liu-2b13b4245/')}
                             style={{cursor: 'pointer'}}/>

                        {/*<a href="https://github.com/quanyoya" target="_blank">*/}
                        {/*    <img src={github} alt="github" style={{cursor: 'pointer'}}/>*/}
                        {/*</a>*/}
                        {/*<a href="https://www.linkedin.com/in/jyuan-liu-2b13b4245/" target="_blank">*/}
                        {/*    <img src={linkedin} alt="linkedin" style={{cursor: 'pointer'}}/>*/}
                        {/*</a>*/}
                    </div>
                </div>
            </section>

            {/*<section className="AboutSkills">*/}
            {/*    <h2>My Skills</h2>*/}
            {/*    <div className="SkillStack">*/}
            {/*        {skillStack.map((skill, index) => (*/}
            {/*            <div className="AboutSkill" key={index}>*/}
            {/*                <img src={skill.img} alt={skill.name} />*/}
            {/*                <p>{skill.name}</p>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>
    );
}

export default About;
