import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgSoli/cover.png';
import background from './imgSoli/background.png';
import interview1 from './imgSoli/interview1.png';
import interview2 from './imgSoli/interview2.png';
import user1 from './imgSoli/user1.png';
import user2 from './imgSoli/user2.png';
import user3 from './imgSoli/user3.png';
import competitor from './imgSoli/competitor.png';
import product1 from './imgSoli/product1.png';
import product2 from './imgSoli/product2.png';
import product3 from './imgSoli/product3.png';
import product4 from './imgSoli/product4.png';
import product5 from './imgSoli/product5.png';
import product6 from './imgSoli/product6.png';
import product7 from './imgSoli/product7.png';
import product8 from './imgSoli/product8.png';
import product9 from './imgSoli/product9.png';
import product10 from './imgSoli/product10.png';
import product11 from './imgSoli/product11.png';
import test from './imgSoli/test.png';
import risk from './imgSoli/risk.png';
import business1 from './imgSoli/business1.png';
import business2 from './imgSoli/business2.png';
import business3 from './imgSoli/business3.png';
import business4 from './imgSoli/business4.png';
import next from './imgSoli/next.png';


const Soli = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="Soli">
            <div className="singleProject">
                <img className="cover" src={cover} alt="Soli"/>
                <h2 className="section-margin">
                    Soli : Safe & Enjoyable Trip Planning for Marginalized Solo Travelers
                </h2>
                <p className="leftAlign font-grey">
                Soli is a mobile app designed to support <u>female and marginalized-gender solo travelers</u>, addressing the unique challenges they face. Through our user research, we identified two key needs: <u>safety</u> and <u>solo-appropriate activities</u>. Soli offers a trusted platform where users can <u>consult a community</u>, <u>plan trips</u>, and access <u>safety advice</u> based on others’ recommendations.
                <br/><br/>
                <li><b>Core features</b>: Discover page, Map pages, Chat, Trip planning tools</li>
                <li><b>Premium features</b>: Group chats with locals, Personalized activity recommendations</li>
                <br/>
                Our product also considers <u>comprehensive marketing and business strategies</u> to ensure growth and sustainability, including a freemium model and plans for user engagement through partnerships and social media. Our goal is to empower solo travelers with confidence and security.
                </p>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Product design</p>
                        <p className="rightAlign"><b>Role :</b> User Research, Prototyping, Marketing Strategy</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2024</p>
                        <p className="centerAlign"><b>Duration :</b> 4 Week</p>
                        <p className="rightAlign"><b>Team :</b> Kariana, Julia </p>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 User Research</h3>
                <div className="section-margin"></div>
                <img className="function-gif" src={background} alt="background" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                
                <img className="function-gif" src={interview1} alt="interview1" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                    We did <b>brainstorming</b> about the question we needed to ask. We grouped our interview questions into several <b>categories</b>. 
                </p>
                <img className="function-gif" src={interview2} alt="interview2" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                This allowed us to cover a broad spectrum of experiences and <b>gather comprehensive data</b>. We aimed to explore users’ current travel habits and uncover challenges they face while planning & traveling
                </p>
                <div className="section-margin"></div>
                <div className="gif-container-2">
                    <img className="function-gif" src={user1} alt="user1"/>
                    <p className="leftAlign font-black">We interviewed a variety of solo travelers and conducted<b> 6 user interviews </b>in total.
                    <br/><br/>Who we mainly interviewed: female solo travelers <b>from 20 years old to 50 years old</b>. 
                    <br/><br/>They either actively travel alone or passively travel alone. They all have concerns about <b>safety issues</b> and <b>have unique insights</b> into traveling alone.
                    </p>
                </div>

                <div className="section-margin"></div>
                <p style={{ width: '66.67%', display: 'block', margin: '0 auto', textAlign: 'center' }}><b><u>Customer Journey Summary</u></b></p>
                <p style={{ width: '80%', display: 'block', margin: '0 auto', textAlign: 'left' }}>
                        <li>Figuring out if a place is <b>safe for solo</b> (not group!) traveling beforehand.</li>
                        <li>Finding activities you can do alone but <b>not feel lonely</b> doing.</li>
                        <li>Finding activities that are <b>specifically advertised/priced</b> for solo travelers.</li>
                        <li>Finding older or more experienced solo travelers to <b>get advice</b> on specific topics or ask specific questions to.</li>
                        <li>Managing different opinions and planning activities on the go when traveling with others.</li>  
                </p>
                <div className="section-margin"></div>
                <div className="gif-container-2">
                    <img className="function-gif" src={user2} alt="user2"/>
                    <p className="leftAlign font-black">
                    We identified user insights based on quotes from our interviewees:
                        <li>Choose to travel alone bc they value flexibility to make their own decisions.</li>
                        <li>Have difficulty finding solo-appropriate activities.</li>
                        <li>Spend a lot of time looking through travel blogs to find the relevant safety information they need.</li>
                    </p>
                </div>

                <img className="function-gif" src={user3} alt="user3" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <div className="gif-container-3">
                    <p className="leftAlign font-black">From the perceptual map, we can see that Soli holds a unique position in the market compared to competitors. We offer a trusted platform that allows users to confidently and safely plan and travel.    
                    </p>
                    <img className="function-gif" src={competitor} alt="competitor"/>
                </div>
                

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">02 Product Overview</h3>
                <img className="function-gif" src={product1} alt="product1" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <img className="function-gif" src={product2} alt="product2" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <img className="function-gif" src={product3} alt="product3" style={{ width: '66.67%', display: 'block', margin: '20px auto' }} />
                <div className="gif-container">
                    <img className="function-gif" src={product4} alt="product4"/>
                    <img className="function-gif" src={product5} alt="product5"/>
                    <img className="function-gif" src={product6} alt="product6"/>
                    <img className="function-gif" src={product7} alt="product7"/>
                    <img className="function-gif" src={product8} alt="product8"/>
                    <img className="function-gif" src={product9} alt="product9"/>
                    <img className="function-gif" src={product10} alt="product10"/>
                    <img className="function-gif" src={product11} alt="product11"/>
                </div>
                <div className="section-margin"></div>
                <img className="function-gif" src={test} alt="test" style={{ width: '66.67%', display: 'block', margin: '0 auto' }} />
                <div className="section-margin"></div>
                <div className="gif-container-3">
                <p className="leftAlign font-black">The risk of safety misinformation in Soli means users could be exposed to unsafe areas due to <b>incorrect information</b>. This is rated as <b>high severity and likely to occur</b>. 
                    <br/><p className="leftAlign font-black">We generated strategies to mitigate the severity of our most critical risks. To reduce the risk, the plan is to grow the user base and improve features for <b>crowdsourced information verification</b>. After these steps, the risk's severity drops to low, but it’s still likely to happen.
                    </p>
                    </p>
                    <img className="function-gif" src={risk} alt="risk"/>                           
                </div>


                <div className="section-margin"></div>
                <h3 className="section-title section-margin">03 Business Strategy</h3>
                <div className="gif-container-2">
                    <img className="function-gif" src={business1} alt="business1"/>
                    <p className="leftAlign font-black">Our business model focuses on building a large initial user base.
                    </p>
                    <img className="function-gif" src={business2} alt="business2"/>
                    <p className="leftAlign font-black">Our company has a large potential user base with an obtainable market of $1.25M.
                    </p>
                    <img className="function-gif" src={business3} alt="business3"/>
                    <p className="leftAlign font-black">Our marketing strategy focuses on building a devoted user base.
                    </p>
                    <img className="function-gif" src={business4} alt="business4"/>
                    <p className="leftAlign font-black">Our product roadmap envisions an evolving app.
                    </p>
                    <img className="function-gif" src={next} alt="next"/>
                    <p className="leftAlign font-black">Our next steps: building an MVP app to eventually partner with a larger existing travel app.
                    </p>
                </div>
            </div>
        </article>
    );
};

export default Soli;
