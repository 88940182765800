import React, { useEffect } from 'react';
import '../App.css';
import cover from './imgSmarkly/cover.png';
import background1 from './imgSmarkly/background1.png';
import background2 from './imgSmarkly/background2.png';
import background3 from './imgSmarkly/background3.png';
import m1 from './imgSmarkly/methods1.png';
import m2 from './imgSmarkly/methods2.png';
import feature2 from './imgSmarkly/feature2.png';
import feature3 from './imgSmarkly/feature3.png';
import feature4 from './imgSmarkly/feature4.png';
import value from './imgSmarkly/value.png';


const Smarkly = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <article className="Smarkly">
            <div className="singleProject">
                <img className="cover" src={cover} alt="Smarkly"/>
                <h2 className="section-margin">
                    Smarkly : Gen-AI Video Marketing Solution
                </h2>
                <p className="leftAlign font-grey">
                    Smarkly is a cutting-edge <u>AI marketing platform</u> crafted specifically for <u>Small & Midsize
                    Businesses</u> seeking to
                    enhance their digital engagement. 
                    <br/><br/>The platform integrates several innovative features:
                    <li>A dynamic video ad creator that allows users to easily produce and customize content tailored to
                        their brand,</li>
                    <li>"My Studio", a comprehensive digital asset management tool, enabling streamlined access and
                        reuse of multimedia,
                    </li>
                    <li>Analytical tools that provide actionable insights and data-driven recommendations to optimize
                        marketing efforts,
                    </li>
                    <li>A competitive analysis feed, offering visibility into local and regional market trends to keep
                        businesses ahead.
                    </li>
                    <br/>
                    Smarkly revolutionizes small business marketing by <u>simplifying content creation and
                    distribution</u>, ensuring broader reach and deeper customer engagement through strategic,
                    AI-enhanced communications. This platform is designed to empower small businesses to effectively
                    compete in a digital-first world.
                </p>
                <div className="section-margin">
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Project Type :</b> Product design, Gen-AI</p>
                        <p className="rightAlign"><b>Role :</b> Prototyping, User Research</p>
                    </div>
                    <div className="projectInfo">
                        <p className="leftAlign"><b>Date :</b> 2024</p>
                        <p className="centerAlign"><b>Duration :</b> 1 Week</p>
                        <p className="rightAlign"><b>Team :</b> Alex, Sijia</p>
                    </div>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">01 Problems and Opportunities</h3>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        • Video content is on the rise, making up most of the internet use and boosting business revenue
                        when used in marketing.
                    </p>
                    <img className="function-gif" src={background1} alt="background1"/>
                    <p className="leftAlign font-grey">
                        • Small businesses juggle many tools for video creation, with high costs suggesting a need for
                        a more unified approach.

                    </p>
                    <img className="function-gif" src={background2} alt="background2"/>
                    <p className="leftAlign font-grey">
                        • Challenges for small businesses include competing with larger companies, limited budgets, and
                        a lack of streamlined marketing tools.
                    </p>
                    <img className="function-gif" src={background3} alt="background3"/>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">02 Solution To Business Pain-Points</h3>
                <p className="font-black">Smarkly’s AI Video Generator gives users <u>two methods</u> to create video
                    content in minutes
                </p>
                <div className="gif-container">
                    <div>
                        <img className="function-gif" src={m1} alt="method 1"/>
                        <p className="font-grey">
                            i Users can submit their pre-existing ideas and employ Smarkly to generate a customized
                            video
                            based on these concepts.
                        </p>
                    </div>
                    <div>
                        <img className="function-gif" src={m2} alt="method 2"/>
                        <p className="font-grey">Users can go through a series of simple prompts where Smarkly uses
                            ii their answers to create
                            tailored videos
                        </p>
                    </div>
                </div>
                <div className="section-margin"></div>
                <p className="font-black">Key Features</p>
                <div className="gif-container-3">
                    <p className="leftAlign font-grey">
                        <li><u>Fast Way to Manage Assets</u></li>
                        <br/>
                        My Assets allows users to store images, add past videos, and upload branding content. Smarkly’s
                        AI power uses training data, web data, as well as user’s uploads to generate engaging marketing
                        content.
                    </p>
                    <img className="function-gif" src={feature2} alt="feature2"/>
                    <p className="leftAlign font-grey">
                        <li><u>Dashboard & Analytics</u></li>
                        <br/>
                        Smarkly provides detailed analytics and insights on video performance, including views,
                        engagement,
                        click-through rates, and audience demographics.
                        Now, users have a centralized platform to track the effectiveness of their content.
                    </p>
                    <img className="function-gif" src={feature3} alt="feature3"/>
                    <p className="leftAlign font-grey">
                        <li><u>Competitor Feed</u></li>
                        <br/>
                        Smarkly leverages AI to dissect competitor videos, give insights about similar local and
                        regional
                        businesses, and craft tailored ecommendations.
                        User can select specific competitors (large or small) for their feed, or Smarkly can recommend
                        competitors in their area.
                    </p>
                    <img className="function-gif" src={feature4} alt="feature4"/>
                </div>


                <div className="section-margin"></div>
                <h3 className="section-title section-margin">03 Value Proposition</h3>
                <img className="function-gif half-width" src={value} alt="Design Solution"/>
                <div className="section-margin"></div>
                <div className="gif-container">
                    <p className="leftAlign font-grey">
                        <li><u>Efficiency & Time Savings :</u>
                            Smarkly streamlines the content creation process, enabling SMB users to produce professional
                            -quality videos in minutes, saving them valuable time and resources that can be allocated to
                            other business tasks.
                        </li>
                        <li><u>Cost-Effectiveness :</u>
                            Smarkly eliminates the need for expensive video production services or hiring dedicated
                            staff,
                            making high-quality marketing videos accessible and affordable for SMBs with limited
                            budgets.
                        </li>
                    </p>
                    <p className="leftAlign font-grey">
                        <li><u>Scalability :</u>
                            Smarkly adapts to the evolving needs of users, allowing them to create a wide range of video
                            content for various marketing campaigns, promotions, and social media channels, helping them
                            stay competitive in their industry.

                        </li>
                        <li><u>Quality :</u>
                            With AI-driven optimization features, Smarkly helps SMBs create videos with the brand
                            assets,
                            messaging, and tone that resonate with their target audience, leading to higher engagement
                            rates and increased brand visibility.
                        </li>
                    </p>
                </div>

                <div className="section-margin"></div>
                <h3 className="section-title section-margin">04 Additional Features</h3>
                <div className="feature-chart">
                    <div className="chart-row">
                        <div className="chart-cell feature-title">Customizable Templates</div>
                        <div className="chart-cell feature-description">
                            SMB users can choose from a variety of pre-designed templates tailored for different
                            industries, events, or purposes, and customize them with their branding, colors, and
                            messaging.
                        </div>
                    </div>
                    <div className="chart-row">
                        <div className="chart-cell feature-title">Social Media Integration</div>
                        <div className="chart-cell feature-description">
                            Seamless integration with popular social media platforms allows SMBs to directly upload
                            their videos to platforms like Facebook, Instagram, YouTube, LinkedIn, and Twitter,
                            streamlining the distribution process.
                        </div>
                    </div>
                    <div className="chart-row">
                        <div className="chart-cell feature-title">AI-Driven Optimization</div>
                        <div className="chart-cell feature-description">
                            Utilizes AI algorithms to analyze user data and optimize video content for maximum reach and
                            engagement, suggesting improvements based on performance metrics.
                        </div>
                    </div>
                    <div className="chart-row">
                        <div className="chart-cell feature-title">Multi-Format Output</div>
                        <div className="chart-cell feature-description">
                            Supports the creation of videos in various formats and aspect ratios optimized for different
                            social media platforms, ensuring compatibility and optimal viewing experiences across
                            devices.
                        </div>
                    </div>
                    <div className="chart-row">
                        <div className="chart-cell feature-title">Script Generation</div>
                        <div className="chart-cell feature-description">
                            Automatically generates scripts, transcripts, subtitles, and captions for videos, improving
                            accessibility and enabling SMBs to reach a broader audience, including those who prefer to
                            watch videos with sound off, or to send scripts to influencers for filming.
                        </div>
                    </div>
                </div>

                <h3 className="section-title section-margin">05 Project Demo</h3>
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/vw7_qTEwE64?si=KzvRJdtXJkNm8vUi"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </article>
    );
};

export default Smarkly;
