import React, { useState } from 'react';
import Bounce from './Bounce'; // Import your Bounce component

function PasswordProtectedBounce() {
    const [password, setPassword] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);

    const correctPassword = 'QZong';

    const handlePasswordSubmit = () => {
        if (password === correctPassword) {
            setAccessGranted(true);
        } else {
            alert('Incorrect password');
        }
    };

    if (accessGranted) {
        return <Bounce />;
    }

    return (
        <div style={styles.container}>
            <div style={styles.form}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    style={styles.input}
                />
                <button onClick={handlePasswordSubmit} style={styles.button}>Submit</button>
            </div>
        </div>
    );
}

// Add CSS styles here
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px', // Space between the input and button
    },
    input: {
        padding: '10px',
        fontSize: '20px', // Make the text bigger
        width: '300px',
        borderRadius: '5px',
        border: '2px solid #ccc',
    },
    button: {
        padding: '10px 20px',
        fontSize: '18px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: 'black',
        color: 'white',
        cursor: 'pointer',
    }
};

export default PasswordProtectedBounce;
